var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade CommentRemoveRequest",
      attrs: { tabindex: "-1", "aria-hidden": "true" }
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c(
          "form",
          {
            staticClass: "modal-content",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              }
            }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("label", { staticClass: "mb-3 d-block" }, [
                _c("span", { staticClass: "form-label d-block" }, [
                  _vm._v("Имя")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", required: "" },
                  domProps: { value: _vm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.name = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "mb-3 d-block" }, [
                _c("span", { staticClass: "form-label d-block" }, [
                  _vm._v("E-mail")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "email", required: "" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "mb-3 d-block" }, [
                _c("span", { staticClass: "form-label d-block" }, [
                  _vm._v("Причина удаления")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.why,
                      expression: "why"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { required: "" },
                  domProps: { value: _vm.why },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.why = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { ref: "recaptcha", staticClass: "mb-3" })
            ]),
            _vm._v(" "),
            _vm._m(1)
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("Заявка на удаление отзыва")
      ]),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close"
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-bs-dismiss": "modal" }
        },
        [_vm._v("Закрыть")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Отправить")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }