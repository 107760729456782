var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.display
    ? _c("section", { staticClass: "Chart" }, [
        _c("div", { ref: "chart", staticClass: "Chart__chart" })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }