<template>
    <div class="card mb-3" :class="borderClass">
        <div class="card-body">
            <div v-if="step === 0" class="inform_right d-sm-flex align-items-center">
              <a href="#" class="inform_btn" @click.prevent="analyzePhone">Проанализировать номер</a>
            </div>
            <div v-if="step === 1" class="inform_right d-sm-flex align-items-center">
              <a href="#" class="inform_btn" @click.prevent="moveToForm">Поделиться информацией о номере</a>
              <h5 v-html="text" class="mt-2"></h5>
            </div>
            <div v-if="step === 100">
                <h4>Спасибо за отзыв!</h4>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        created() {
            this.$store.dispatch('phoneViewed', this.$store.state.phone);
        },
        data: () => { return {
            step: 0,
        }},
        computed: {
            borderClass() {
              return ['border-' + this.$store.state.currentCommentAnalysis.color];
            },
            buttonClass() {
              return ['btn-' + this.$store.state.currentCommentAnalysis.color];
            },
            text() {
              return this.$store.state.currentCommentAnalysis.text;
            }
        },
        methods: {
            moveToForm() {
                this.step = 100;
                const top = document.querySelector('.review_block').getBoundingClientRect().top + window.pageYOffset - 60;
                window.scrollTo({ 'behavior': 'smooth', 'top': top });
            },
            analyzePhone() {
                this.step = 1;
                this.$store.dispatch('getPhoneAnalysis', this.$store.state.phone);
            }
        }
    }
</script>

<style scoped>
    h5 {
        display: inline-block;
        margin-bottom: 0;
        margin-left: 15px;
    }
</style>
