<template>
  <section class="organization same_section" v-if="list.length">
    <div class="container">
      <h2 class="main_title">{{ title }}</h2>
      <form class="organization_search d-sm-block d-none position-relative">
        <input type="text" v-model="filter" placeholder="Введите название">
<!--        <input type="text" placeholder="Введите название">
        <button class="organ_search position-absolute z-3" type="submit">Найти</button>-->
      </form>

      <!-- mobile -->
<!--      <form class="mobile_search position-relative d-sm-none">
        <input type="text" class="bg-white" placeholder="Введите номер телефона">
        <button type="submit" class="mobile_send_btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M21 21.0002L16.657 16.6572M16.657 16.6572C17.3998 15.9143 17.9891 15.0324 18.3912 14.0618C18.7932 13.0911 19.0002 12.0508 19.0002 11.0002C19.0002 9.9496 18.7932 8.90929 18.3912 7.93866C17.9891 6.96803 17.3998 6.08609 16.657 5.34321C15.9141 4.60032 15.0321 4.01103 14.0615 3.60898C13.0909 3.20693 12.0506 3 11 3C9.94936 3 8.90905 3.20693 7.93842 3.60898C6.96779 4.01103 6.08585 4.60032 5.34296 5.34321C3.84263 6.84354 2.99976 8.87842 2.99976 11.0002C2.99976 13.122 3.84263 15.1569 5.34296 16.6572C6.84329 18.1575 8.87818 19.0004 11 19.0004C13.1217 19.0004 15.1566 18.1575 16.657 16.6572Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </button>
      </form>-->

      <div class="organ_block d-flex flex-column">
        <div class="organ_item">
<!--          <p class="organ_letter d-inline-block">A</p>-->
          <div class="organ_buttons_block d-flex flex-wrap">
            <a :href="`/${action}/${item.id}`" class="organ_btn_item organ_btn_item1" v-for="item in filteredList">
              {{ item.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        data: () => { return {
            filter: '',
        }},
        props: {
            title: String,
            list: Array,
            result: Array,
            action: String,
        },
        computed: {
            filteredList() {
                if (!this.filter) return this.list;
                return this.list.filter(i => String(i.name).toLowerCase().includes(this.filter.toLowerCase()));
            }
        }
    }
</script>
