document.addEventListener('DOMContentLoaded', () => {
    for (let form of document.querySelectorAll('.js-form-phone')) {

        form.addEventListener('submit', e => {
            const input = e.target.querySelector('input[type=tel]');
            if (input) {
                let phone = input.value.replace(/[^\d]*/g, '');
                phone = phone.replace(/.*(\d{10})$/, '$1');
                document.location.href = document.location.origin + '/search/' + phone;
            }
            e.preventDefault();
        });

        const button = form.querySelector('button[type=submit]');
        if (button) button.addEventListener('click', e => {
            form.submit();
        });
    }

    for (let staticClose of document.querySelectorAll('.Static__close')) {
        staticClose.addEventListener('click', e => {
            app.vue.$emit('remove-comment', e.target.dataset.key);
            e.preventDefault();
        });
    }

    for (let staticLike of document.querySelectorAll('.Like_button_action')) {
        staticLike.addEventListener('click', e=> {
            let likeTarget = e.target.matches(['svg', 'span'])
                ? e.target.parentElement
                : e.target;
            let counter = likeTarget.getElementsByTagName('span')[1];
            counter.textContent = parseInt(counter.textContent) + 1;
            app.vue.$store.dispatch('likeComment', {
                comment: likeTarget.dataset.id,
            }).then(() => {});
            e.preventDefault();
        });
    }

    for (let staticDislike of document.querySelectorAll('.Dislike_button_action')) {
        staticDislike.addEventListener('click', e=> {
            let dislikeTarget = e.target.matches(['svg', 'span'])
                ? e.target.parentElement
                : e.target;
            let counter = dislikeTarget.getElementsByTagName('span')[1];
            counter.textContent = parseInt(counter.textContent) + 1;
            app.vue.$store.dispatch('dislikeComment', {
                comment: dislikeTarget.dataset.id,
            }).then(() => {});
            e.preventDefault();
        });
    }
});
