<template>
    <div class="Toast toast show fade d-flex text-white border-0 mb-3" :class="bgClass">
        <div class="toast-body" v-html="toast.text"></div>
        <button
            type="button"
            class="btn-close btn-close-white ms-auto me-2 align-self-center"
            aria-label="Close"
            @click="$emit('close')"
        ></button>
    </div>
</template>

<script>
    export default {
        props: {
            toast: Object,
        },
        computed: {
            bgClass() {
                return ['bg-' + this.toast.type];
            }
        }
    }
</script>

<style>
    .Toast {
        pointer-events: all;
    }
</style>