<template>
  <div :class="parentStyles" class="align-items-center">
    <p :class="childStyle + ' review_item_' + item.class" v-for="item in summary" :key="item.id" v-if="item.votes">
      {{ item.type }} - {{ item.votes }}
    </p>
  </div>
</template>

<script>
    export default {
        props: {
            comments: Array,
            commentTypes: Array,
            views: Number,
            mobileStyles: Boolean
        },
        computed: {
            summary() {
                const result = this.commentTypes.map(item => {
                    return item;
                });
                result.forEach(item => {
                    item.votes = this.votes[item.id];
                });
                return result;
            },
            votes() {
                const result = {};
                this.commentTypes.forEach(item => {
                    result[item.id] = 0;
                });
                this.comments.forEach(item => {
                    result[item.type_id]++;
                });
                return result;
            },
            maxVote() {
                return Math.max(...Object.values(this.votes));
            },
            parentStyles() {
                return this.mobileStyles
                    ? 'number_mobile_inform d-flex flex-wrap d-sm-none justify-content-center'
                    : 'inform_right d-none d-sm-flex';
            },
            childStyle() {
                return this.mobileStyles
                    ? 'number_mobile_status'
                    : 'com_status';
            }
        }
    }
</script>

