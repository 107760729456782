var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mb-3", class: _vm.borderClass }, [
    _c("div", { staticClass: "card-body" }, [
      _vm.step === 0
        ? _c(
            "div",
            { staticClass: "inform_right d-sm-flex align-items-center" },
            [
              _c(
                "a",
                {
                  staticClass: "inform_btn",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.analyzePhone.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Проанализировать номер")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 1
        ? _c(
            "div",
            { staticClass: "inform_right d-sm-flex align-items-center" },
            [
              _c(
                "a",
                {
                  staticClass: "inform_btn",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.moveToForm.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Поделиться информацией о номере")]
              ),
              _vm._v(" "),
              _c("h5", {
                staticClass: "mt-2",
                domProps: { innerHTML: _vm._s(_vm.text) }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 100
        ? _c("div", [_c("h4", [_vm._v("Спасибо за отзыв!")])])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }