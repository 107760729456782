var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "review_block" }, [
    _c("h2", { staticClass: "review_title" }, [
      _vm._v("\n    Добавить отзыв\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "review_buttons d-flex flex-wrap" },
      _vm._l(_vm.commentTypes, function(item) {
        return _c("div", { key: item.id }, [
          _c(
            "a",
            {
              staticClass: "review_item",
              class: _vm.classCard(item),
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.setActive(item)
                }
              }
            },
            [_vm._v(_vm._s(item.type))]
          )
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "review_form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "review_form_title d-flex align-items-center" },
          [
            _c("p", { staticClass: "input_label" }, [_vm._v("Псевдоним")]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "input_grey_label",
                attrs: { id: "alias-selection" },
                on: {
                  click: function($event) {
                    return _vm.aliasSelection()
                  }
                }
              },
              [_vm._v("Сгенерировать")]
            )
          ]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          staticClass: "review_text_input",
          attrs: { type: "text", required: "" },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.name = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "review_textarea d-flex flex-column" }, [
          _c("span", [_vm._v("Ваш отзыв")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.comment,
                expression: "comment"
              }
            ],
            attrs: { required: "" },
            domProps: { value: _vm.comment },
            on: {
              input: function($event) {
                if ($event.target.composing) return
                _vm.comment = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form_buttons d-flex" }, [
          _c(
            "button",
            {
              staticClass: "review_send",
              attrs: { type: "submit", disabled: !_vm.recaptchaResponce }
            },
            [_vm._v("Отправить отзыв")]
          ),
          _vm._v(" "),
          _c("div", { ref: "recaptcha" })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }