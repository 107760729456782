import 'bootstrap/dist/css/bootstrap.min.css';
import boostrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import './fontawesome/css/fontawesome.min.css';
import './fontawesome/css/solid.min.css';
import './fontawesome/css/brands.min.css';
import '@splidejs/splide/dist/css/splide-core.min.css';
import './css/main.css';
import './script';
import Vue from 'vue';
import store from './store';

const components = require.context('./components', false, /\.vue$/);
components.keys().forEach(i => {
    const name = i.match(/([^/]+)\.vue$/)[1];
    Vue.component(name, components(i).default);
});

window.app.bootstrap = boostrap;

window.app.vue = new Vue({
    el: '#v-root',
    data: {},
    store: store,
    methods: {
        plural(number, one, two, five) {
            var n = Math.abs(number);
            n %= 100;
            if (n >= 5 && n <= 20) { return five; }
            n %= 10;
            if (n === 1) { return one; }
            if (n >= 2 && n <= 4) { return two; }
            return five;
        }
    }
});