<template>
      <div class="inform_right d-none d-sm-flex align-items-center">
        <p class="com_status" :class="'review_item_' + item.class" v-for="item in summary" :key="item.id" v-if="item.votes">
          {{ item.type }} - {{ item.percent }}%
        </p>
      </div>
</template>

<script>
    export default {
        props: {
            comments: Array,
            commentTypes: Array,
        },
        computed: {
            summary() {
                const result = this.commentTypes.map(item => {
                    return item;
                });
                result.forEach(item => {
                    item.votes = this.votes[item.id];
                    item.percent = Math.round(this.votes[item.id] / this.comments.length * 100);
                    item.borderColor = 'border-' + item.class;
                    item.bgColor = 'bg-' + item.class;

                    const active = item.votes === this.maxVote;

                    item.cardClass = {};
                    item.cardClass['text-white'] = active;
                    item.cardClass['bg-' + item.class] = active;
                });
                return result;
            },
            votes() {
                const result = {};
                this.commentTypes.forEach(item => {
                    result[item.id] = 0;
                });
                this.comments.forEach(item => {
                    result[item.type_id]++;
                });
                return result;
            },
            maxVote() {
                return Math.max(...Object.values(this.votes));
            }
        }
    }
</script>