var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "blog_block d-none d-md-flex flex-wrap justify-content-between"
    },
    [
      _vm._l(_vm.news, function(item) {
        return _c("NewsCard", { tag: "component", attrs: { article: item } })
      }),
      _vm._v(" "),
      !_vm.news.length
        ? _c("p", [_vm._v("Новости пока отсутствуют")])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }