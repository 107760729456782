var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inform_right d-none d-sm-flex align-items-center" },
    _vm._l(_vm.summary, function(item) {
      return item.votes
        ? _c(
            "p",
            {
              key: item.id,
              staticClass: "com_status",
              class: "review_item_" + item.class
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(item.type) +
                  " - " +
                  _vm._s(item.percent) +
                  "%\n  "
              )
            ]
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }