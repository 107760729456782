var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list.length
    ? _c("section", { staticClass: "organization same_section" }, [
        _c("div", { staticClass: "container" }, [
          _c("h2", { staticClass: "main_title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass:
                "organization_search d-sm-block d-none position-relative"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter,
                    expression: "filter"
                  }
                ],
                attrs: { type: "text", placeholder: "Введите название" },
                domProps: { value: _vm.filter },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.filter = $event.target.value
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "organ_block d-flex flex-column" }, [
            _c("div", { staticClass: "organ_item" }, [
              _c(
                "div",
                { staticClass: "organ_buttons_block d-flex flex-wrap" },
                _vm._l(_vm.filteredList, function(item) {
                  return _c(
                    "a",
                    {
                      staticClass: "organ_btn_item organ_btn_item1",
                      attrs: { href: `/${_vm.action}/${item.id}` }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }