<template>
  <div class="blog_block d-none d-md-flex flex-wrap justify-content-between">
    <component is="NewsCard" :article="item" v-for="item in news"></component>
    <p v-if="!news.length">Новости пока отсутствуют</p>
  </div>
</template>

<script>
export default {
    props: {
        news: Array,
    }
}
</script>