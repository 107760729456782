var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "blog_item" }, [
    _c("div", { staticClass: "blog_img" }, [
      _vm.article.image
        ? _c("img", {
            attrs: { src: _vm.article.image, alt: _vm.article.title }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "blog_name" }, [
      _vm._v(_vm._s(_vm.article.title) + "?")
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "blog_desc",
      domProps: { innerHTML: _vm._s(_vm.article.excert) }
    }),
    _vm._v(" "),
    _c("a", { staticClass: "blog_more", attrs: { href: _vm.article.url } }, [
      _vm._v("ПОДРОБНЕЕ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }