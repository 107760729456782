<template>
  <div class="commentary_block d-flex flex-column ">
    <component is="Comment" :comment="item" v-for="item in comments" :key="item.id"></component>
    <p v-if="!comments.length">Отзывы пока отсутствуют</p>
    <div class="commentary_main_bottom d-flex align-items-center justify-content-center">
      <a href="#" class="commentary_button" @click.prevent="showMore" v-show="isShowMoreVisible">Показать еще</a>
    </div>
  </div>
</template>

<script>
    export default {
        props: {
            comments: Array,
            page: Number,
            isShowMoreVisible: Boolean
        },
        methods: {
          showMore() {
            this.$store.dispatch('loadComments', {
              page: this.page + 1,
            });
          }
        }
    }
</script>
