import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const timeout = 2000;
const defaultPage = 1;
const csrfName = document.querySelector('meta[name="csrf-param"]')?.content;
const csrfValue = document.querySelector('meta[name="csrf-token"]')?.content;

const debounce = (fn, ms) => {
    let lock = false;
    return function() {
        if (lock) return;
        fn.apply(this, arguments);
        lock = true;
        setTimeout(() => lock = false, ms);
    };
}

const loadRecaptcha = (() => {
    let isLoading = false;
    let isLoaded = false;
    const callbacks = [];

    return callback => {
        if (isLoaded) {
            callback();
            return;
        }

        if (!isLoading) {
            window.recaptchaOnload = () => {
                isLoaded = true;
                callbacks.map(i => i());
            }
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnload';
            document.head.appendChild(script);
            isLoading = true;
        }

        callbacks.push(callback);
    }
})();

const formatRangeNumber = number => String(number).padStart(7, '0').replace(/^(.{3})/, '$1 ');

const store = new Vuex.Store({
    state: {
        comments: [],
        currentCommentsPage: 1,
        currentCommentAnalysis: {
            color: 'white'
        },
        commentTypes: [],
        phone: null,
        toasts: [],
        range: {
            prefix: [],
            owners: [],
            regions: [],
        }
    },
    getters: {
        config: () => window.app.config,
    },
    mutations: {
        setComments: (state, comments) => {
            state.comments = Array.from(comments);
            state.currentCommentsPage = defaultPage;
        },
        addMoreComments: (state, comments) => {
            state.comments = state.comments.concat(Array.from(comments));
            state.currentCommentsPage++;
        },
        setCommentTypes: (state, types) => {
            state.commentTypes = Array.from(types);
        },
        setPhone: (state, phone) => {
            state.phone = phone;
        },
        setToasts: (state, toasts) => {
            state.toasts = Array.from(toasts);
        },
        setRangePrefix: (state, prefix) => {
            state.range.prefix = Array.from(prefix);
        },
        setCommentAnalysis: (state, analysis) => {
            state.currentCommentAnalysis = analysis;
        },
        setRangeOwners: (state, owners) => {
            state.range.owners = Array.from(owners);
        },
        setRangeRegions: (state, regions) => {
            state.range.regions = Array.from(regions);
        },
    },
    actions: {
        init: ({ commit }) => {
            window.app.config.comments && commit('setComments', window.app.config.comments);
            window.app.config.commentTypes && commit('setCommentTypes', window.app.config.commentTypes);
            window.app.config.phone && commit('setPhone', window.app.config.phone);
        },
        api: (context, { action, payload }) => {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.timeout = timeout;
                xhr.onreadystatechange = e => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            resolve(JSON.parse(xhr.response))
                        } else {
                            reject('Ошибка отправки запроса')
                        }
                    }
                };
                xhr.ontimeout = () => {
                    reject('Сервер не отвечает');
                };

                let fd = payload;
                if (!(payload instanceof FormData)) {
                    fd = new FormData();
                    for (let key in payload) {
                        if (payload.hasOwnProperty(key)) {
                            fd.append(key, payload[key]);
                        }
                    }
                }
                fd.append(csrfName, csrfValue);

                xhr.open('post', window.app.config.api + action, true)
                xhr.send(fd);
            });
        },
        addComment: debounce(({ state, commit, dispatch }, { name, email, type, text, recaptcha }) => {
            return new Promise(((resolve, reject) => {
                dispatch('api', {
                    action: 'add-comment',
                    payload: {
                        phone: state.phone,
                        name,
                        email,
                        text,
                        type,
                        recaptcha
                    }
                })
                .then(reply => {
                    if (!reply.result) {
                        reply = {
                            result: 'error',
                            message: 'Ошибка добавления отзыва',
                        }
                        dispatch('showApiResult', reply);
                        reject();
                    }

                    if (reply.result === 'success') {
                        commit('setComments', reply.data.comments);
                    }
                    dispatch('showApiResult', reply);
                    resolve();
                })
                .catch(reply => {
                    dispatch('showApiResult', {
                        result: 'error',
                        message: reply,
                    });
                    reject();
                });
            }));
        }, timeout),
        loadComments: debounce(({ state, commit, dispatch }, { page }) => {
            return new Promise(((resolve, reject) => {
                    dispatch('api', {
                        action: 'get-more-comments',
                        payload: {
                            page,
                        }
                    })
                    .then(reply => {
                        if (!reply.result) {
                            reply = {
                                result: 'error',
                                message: 'Ошибка загрузки отзывов',
                            }
                            dispatch('showApiResult', reply);
                            reject();
                        }

                        if (reply.result === 'success') {
                            commit('addMoreComments', reply.data.comments);
                        }
                        resolve();
                    })
                    .catch(reply => {
                        dispatch('showApiResult', {
                            result: 'error',
                            message: reply,
                        });
                        reject();
                    });
            }));
        }, timeout),
        showApiResult: ({ dispatch }, reply) => {
            const types = {
                success: 'success',
                error: 'danger',
            };
            const toastType = types[reply.result] || 'info';
            dispatch('addToast', { toast: {
                type: toastType,
                text: reply.message,
            }});
        },
        removeToast: ({ state, commit }, toast) => {
            const toasts = state.toasts.filter(i => i !== toast);
            commit('setToasts', toasts);
        },
        addToast: ({ state, commit, dispatch }, { toast, autoRemove = true}) => {
            const toasts = state.toasts;
            toasts.push(toast);
            commit('setToasts', toasts);
            if (autoRemove) {
                const last = state.toasts[state.toasts.length - 1];
                setTimeout(last => dispatch('removeToast', last), timeout, last);
            }
        },
        loadRecaptcha: () => {
            return new Promise(resolve => {
                loadRecaptcha(resolve);
            });
        },
        getPrefix: ({ dispatch, commit }, prefix) => {
            dispatch('api', {
                action: 'get-prefix',
                payload: {
                    prefix,
                }
            }).then(result => {
                const rangePrefix = result.data.map(i =>
                    `(${i.prefix}) ${formatRangeNumber(i.begin)} — ${formatRangeNumber(i.end)}, ${i.owner}, ${i.region}`
                );
                commit('setRangePrefix', rangePrefix);
            }).catch(() => {
                dispatch('showApiResult', {
                    result: 'error',
                    message: 'Ошибка сервера',
                });
            });
        },
        getPhoneAnalysis: ({ dispatch, commit }, phone) => {
            dispatch('api', {
                action: 'get-phone-analysis',
                payload: {
                    number: phone,
                }
            }).then(reply => {
                if (!reply.result) {
                    reply = {
                        result: 'error',
                        message: 'Ошибка получения анализа номера',
                    }
                    dispatch('showApiResult', reply);
                    reject();
                }

                if (reply.result === 'success') {
                    commit('setCommentAnalysis', reply.data);
                }
            }).catch(() => {
                dispatch('showApiResult', {
                    result: 'error',
                    message: 'Ошибка сервера',
                });
            });
        },
        removeComment: debounce(({ state, commit, dispatch }, { comment, name, email, why, recaptcha }) => {
            return new Promise(((resolve, reject) => {
                dispatch('api', {
                    action: 'remove-comment',
                    payload: {
                        comment,
                        name,
                        email,
                        why,
                        recaptcha
                    }
                }).then(reply => {
                    if (!reply.result) {
                        reply = {
                            result: 'error',
                            message: 'Ошибка добавления заявки',
                        }
                        dispatch('showApiResult', reply);
                        reject();
                    }

                    dispatch('showApiResult', reply);
                    resolve();
                }).catch(reply => {
                    dispatch('showApiResult', {
                        result: 'error',
                        message: reply,
                    });
                    reject();
                });
            }));
        }, timeout),
        likeComment: ({ state, commit, dispatch }, { comment }) => {
            return new Promise(((resolve, reject) => {
                dispatch('api', {
                    action: 'like-comment',
                    payload: {
                        comment,
                    }
                }).then(reply => {
                    if (!reply.result) {
                        reply = {
                            result: 'error',
                            message: 'Ошибка отправки данных',
                        }
                        dispatch('showApiResult', reply);
                        reject();
                    }

                    if (reply.result === 'success') {
                        resolve();
                    }
                }).catch(reply => {
                    dispatch('showApiResult', {
                        result: 'error',
                        message: reply,
                    });
                    reject();
                });
            }));
        },
        dislikeComment: ({ state, commit, dispatch }, { comment }) => {
            return new Promise(((resolve, reject) => {
                dispatch('api', {
                    action: 'dislike-comment',
                    payload: {
                        comment,
                    }
                }).then(reply => {
                    if (!reply.result) {
                        reply = {
                            result: 'error',
                            message: 'Ошибка отправки данных',
                        }
                        dispatch('showApiResult', reply);
                        reject();
                    }

                    if (reply.result === 'success') {
                        resolve();
                    }
                }).catch(reply => {
                    dispatch('showApiResult', {
                        result: 'error',
                        message: reply,
                    });
                    reject();
                });
            }));
        },
        phoneViewed: ({ state, commit, dispatch }, phone) => {
            return new Promise(((resolve, reject) => {
                dispatch('api', {
                    action: 'phone-viewed',
                    payload: {
                        number: phone,
                    }
                }).then(reply => {
                    if (!reply.result) {
                        reply = {
                            result: 'error',
                            message: 'Ошибка отправки данных',
                        }
                        dispatch('showApiResult', reply);
                        reject();
                    }

                    if (reply.result === 'success') {
                        resolve();
                    }
                }).catch(reply => {
                    dispatch('showApiResult', {
                        result: 'error',
                        message: reply,
                    });
                    reject();
                });
            }));
        },
    }
});

store.dispatch('init');

window.app.store = store;
export default store;
