<template>
    <div class="commentary_item">
      <div class="commentary_top d-flex align-items-center justify-content-between">
        <div class="com_top_left d-flex align-items-center">
          <a :href="comment.url" class="com_tel">{{ comment.phone }}</a>
          <p :class="bgClass">{{ comment.type.type }}</p>
        </div>
        <div class="com_top_right d-flex align-items-center">
          <p class="com_date date_time">{{ comment.createdDate }}</p>
          <p class="com_time date_time">{{ comment.createdTime }}</p>
          <button class="btn btn-close btn-close-black ms-2 Comment__close" @click.prevent="close"></button>
        </div>
      </div>

      <p class="com_person">{{ comment.name }}</p>
      <p class="com_description">{{ comment.text }}</p>

      <div class="commentary_bottom d-flex align-items-center">
        <p class="otziv">Вам помог этот отзыв?</p>
        <a href="#" class="com_yes_btn com_bottom_btn" :data-id="comment.id" @click.prevent="like">
          <span>Да, спасибо</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M11.9703 13.848L13.7603 7.51469C13.7884 7.41553 13.793 7.31123 13.774 7.20997C13.7549 7.10871 13.7126 7.01325 13.6504 6.93108C13.5883 6.84892 13.5079 6.78228 13.4156 6.7364C13.3233 6.69053 13.2217 6.66667 13.1187 6.66669H9.29167C9.23681 6.66669 9.18266 6.65431 9.13325 6.63048C9.08383 6.60665 9.04044 6.57198 9.00628 6.52905C8.97213 6.48611 8.9481 6.43603 8.93599 6.38253C8.92388 6.32902 8.924 6.27347 8.93633 6.22002L9.103 5.49869C9.25567 4.83702 9.30467 4.15535 9.24833 3.47869L9.21767 3.11102C9.18481 2.71658 9.01321 2.34656 8.73333 2.06669C8.47733 1.81062 8.13009 1.66674 7.768 1.66669H7.688C7.469 1.66669 7.26733 1.78535 7.16133 1.97669L6.275 3.57169C5.87766 4.28703 5.28535 4.87482 4.567 5.26669L2.34733 6.47669C2.2421 6.53411 2.1543 6.61885 2.09317 6.72198C2.03205 6.8251 1.99986 6.94281 2 7.06269V13.6667C2 13.8435 2.07024 14.0131 2.19526 14.1381C2.32029 14.2631 2.48986 14.3334 2.66667 14.3334H11.329C11.4742 14.3333 11.6153 14.2859 11.7311 14.1983C11.8469 14.1107 11.9308 13.9877 11.9703 13.848Z"
                stroke="#11941E" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
          </svg>
          <span>{{ comment.likes }}</span>
        </a>

        <a href="#" class="com_no_btn com_bottom_btn" :data-id="comment.id" @click.prevent="dislike">
          <span>Нет</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M11.9703 2.15198L13.7603 8.48531C13.7884 8.58447 13.793 8.68877 13.774 8.79003C13.7549 8.89129 13.7126 8.98675 13.6504 9.06892C13.5883 9.15108 13.5079 9.21772 13.4156 9.2636C13.3233 9.30947 13.2217 9.33333 13.1187 9.33331H9.29167C9.23681 9.33331 9.18266 9.34569 9.13325 9.36952C9.08383 9.39335 9.04044 9.42802 9.00628 9.47095C8.97213 9.51389 8.9481 9.56397 8.93599 9.61747C8.92388 9.67098 8.924 9.72653 8.93633 9.77998L9.103 10.5013C9.25567 11.163 9.30467 11.8446 9.24833 12.5213L9.21767 12.889C9.18481 13.2834 9.01321 13.6534 8.73333 13.9333C8.47733 14.1894 8.13009 14.3333 7.768 14.3333H7.688C7.469 14.3333 7.26733 14.2146 7.16133 14.0233L6.275 12.4283C5.87766 11.713 5.28535 11.1252 4.567 10.7333L2.34733 9.52331C2.2421 9.46589 2.1543 9.38115 2.09317 9.27802C2.03205 9.1749 1.99986 9.05719 2 8.93731V2.33331C2 2.1565 2.07024 1.98693 2.19526 1.86191C2.32029 1.73688 2.48986 1.66665 2.66667 1.66665H11.329C11.4742 1.66669 11.6153 1.71411 11.7311 1.80171C11.8469 1.8893 11.9308 2.01229 11.9703 2.15198Z"
                stroke="#EA1919" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
          </svg>
          <span>{{ comment.dislikes }}</span>
        </a>
      </div>

      <div itemscope itemtype="https://schema.org/Review" class="Comment__micro">
        <div itemprop="itemReviewed" itemscope itemtype="https://schema.org/Organization">
          <img itemprop="image" src="/favicon.png" :alt="`Кто звонил - ${comment.phone}`"/>
          <span itemprop="name">Кто звонил - {{ comment.phone }}</span>
          <span itemprop="telephone">{{ comment.phone }}</span>
        </div>
        <span itemprop="author" itemscope itemtype="https://schema.org/Person">
          <span itemprop="name">{{ comment.name }}</span>
        </span>
        <span itemprop="reviewBody">{{ comment.text }}</span>
        <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
          <meta itemprop="name" content="scamcall.ru">
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        props: {
            comment: Object,
        },
        computed: {
            borderClass() {
                return 'border-' + this.comment.type.class;
            },
            bgClass() {
                return 'com_status review_item_' + this.comment.type.class;
            },
        },
        methods: {
            close() {
                this.$root.$emit('remove-comment', this.comment.id);
            },
            like() {
                this.$store.dispatch('likeComment', {
                    comment: this.comment.id,
                }).then(() => {
                    this.comment.likes++;
                });
            },
            dislike() {
                this.$store.dispatch('dislikeComment', {
                    comment: this.comment.id,
                }).then(() => {
                    this.comment.dislikes++;
                });
            }
        }
    }
</script>

<style>
    .Comment__close {
        z-index: 10;
    }
    .Comment__micro {
        display: none;
    }
    .Like__button {
        color: #198754;
        position: absolute;
        right: 60px;
        bottom: 2px;
        z-index: 1;
    }
    .Like__button span, .Like__button i {
      pointer-events: none;
    }
    .Dislike__button {
        color: #dc3545;
        position: absolute;
        right: 10px;
        bottom: 2px;
        z-index: 1;
    }
    .Dislike__button span, .Dislike__button i {
       pointer-events: none;
    }
</style>
