<template>
  <div class="review_block">
    <h2 class="review_title">
      Добавить отзыв
    </h2>
    <div class="review_buttons d-flex flex-wrap">
      <div v-for="item in commentTypes" :key="item.id">
        <a href="#" class="review_item" :class="classCard(item)" @click.prevent="setActive(item)">{{ item.type }}</a>
      </div>
    </div>

    <form class="review_form" @submit.prevent="submit">
      <div class="review_form_title d-flex align-items-center">
        <p class="input_label">Псевдоним</p>
        <span id="alias-selection" class="input_grey_label" @click="aliasSelection()">Сгенерировать</span>
      </div>
      <input type="text" class="review_text_input" required v-model="name">
      <label class="review_textarea d-flex flex-column">
        <span>Ваш отзыв</span>
        <textarea v-model="comment" required></textarea>
      </label>
      <div class="form_buttons d-flex">
        <button type="submit" class="review_send" :disabled="!recaptchaResponce">Отправить отзыв</button>
        <div ref="recaptcha"></div>
      </div>
    </form>
  </div>
</template>

<script>
    export default {
        data: () => { return {
            name: '',
            email: '',
            comment: '',
            selectedTypeId: 0,
            recaptcha: null,
            recaptchaResponce: null,
        }},
        mounted() {
            this.reset();
            this.$store.dispatch('loadRecaptcha')
                .then(() => {
                    this.recaptcha = grecaptcha.render(this.$refs.recaptcha, {
                        sitekey: app.config.recaptcha,
                        callback: response => {
                            this.recaptchaResponce = response;
                        },
                        theme: 'light',
                        size: 'normal',
                    });
                })
        },
        computed: {
            commentTypes() {
                return this.$store.state.commentTypes;
            },
            classBg() {
                return type => ['bg-review_item_' + type.class];
            },
            classCard() {
                return type => {
                    const result = {};
                    result['--active'] = this.isActive(type);
                    result['review_item_' + type.class] = true;
                    result['text-white'] = this.isActive(type);
                    result['bg-review_item_' + type.class] = this.isActive(type);
                    return result;
                };
            },
            classBody() {
                return type => {
                    const result = {};
                    result['text-white'] = this.isActive(type);
                    result['bg-' + type.class] = this.isActive(type);
                    return result;
                };
            },
            isActive() {
                return type => type.id === this.selectedTypeId;
            }
        },
        methods: {
            setActive(type) {
                this.selectedTypeId = type.id;
            },
            aliasSelection() {
                 this.name = 'John Doe ' + Math.round(Math.random() * 100000);
            },
            submit() {
                if (!this.recaptchaResponce) return;
                this.$store.dispatch('addComment', {
                    name: this.name,
                    email: this.email,
                    text: this.comment,
                    type: this.selectedTypeId,
                    recaptcha: this.recaptchaResponce,
                }).then(() => this.reset());
            },
            reset() {
                if (this.commentTypes.length) this.selectedTypeId = this.commentTypes[0].id;
                this.comment = '';
                this.name = '';
                this.email = '';
                this.recaptcha && grecaptcha.reset(this.recaptcha);
                this.recaptchaResponce = null;
            }
        }
    }
</script>
