<template>
    <div>
      <div class="map_call_block" v-if="step === 0">
        <p class="map_call_title">Вам звонили с этого номера?</p>
        <div class="map_call_btn d-flex align-items-center">
          <a href="#" class="yes_call map_call_button d-none d-lg-block" @click.prevent="step = 1">Да</a>
          <a href="#" class="no_call map_call_button d-none d-lg-block" @click.prevent="step = 3">Нет</a>
        </div>
      </div>
      <div class="map_call_block" v-if="step === 1">
        <p class="map_call_title">Вы ответили на звонок?</p>
        <div class="map_call_btn d-flex align-items-center">
          <a href="#" class="yes_call map_call_button d-none d-lg-block" @click.prevent="moveToForm">Да</a>
          <a href="#" class="no_call map_call_button d-none d-lg-block" @click.prevent="step = 2">Нет</a>
        </div>
      </div>
      <div class="map_call_block" v-if="step === 2">
        <p class="map_call_title">По какой причине не смогли ответить?</p>
        <div class="map_call_btn d-flex align-items-center">
          <a href="#" class="yes_call map_call_button d-none d-lg-block" @click.prevent="step = 100">Короткий звонок</a>
          <a href="#" class="yes_call map_call_button d-none d-lg-block" @click.prevent="step = 100">Не беру трубку с незнакомого номера</a>
          <a href="#" class="yes_call map_call_button d-none d-lg-block" @click.prevent="step = 100">Пропущенный звонок</a>
        </div>
      </div>
      <div class="map_call_block" v-if="step === 3">
        <p class="map_call_title">Есть ли у вас информация по этому номеру?</p>
        <div class="map_call_btn d-flex align-items-center">
          <a href="#" class="yes_call map_call_button d-none d-lg-block" @click.prevent="moveToForm">Да</a>
          <a href="#" class="no_call map_call_button d-none d-lg-block" @click.prevent="step = 100">Нет</a>
        </div>
      </div>
      <div class="map_call_block" v-if="step === 100">
        <p class="map_call_title">Спасибо за отзыв!</p>
      </div>
    </div>
</template>

<script>
    export default {
        data: () => { return {
            step: 0,
        }},
        methods: {
            moveToForm() {
                this.step = 100;
                const top = document.querySelector('.review_block').getBoundingClientRect().top + window.pageYOffset - 60;
                window.scrollTo({ 'behavior': 'smooth', 'top': top });
            }
        }
    }
</script>