var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ToastList" },
    _vm._l(_vm.getToasts, function(item) {
      return _c("Toast", {
        tag: "component",
        attrs: { toast: item },
        on: {
          close: function($event) {
            return _vm.close(item)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }