var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "commentary_block d-flex flex-column" },
    [
      _vm._l(_vm.comments, function(item) {
        return _c("Comment", {
          key: item.id,
          tag: "component",
          attrs: { comment: item }
        })
      }),
      _vm._v(" "),
      !_vm.comments.length
        ? _c("p", [_vm._v("Отзывы пока отсутствуют")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "commentary_main_bottom d-flex align-items-center justify-content-center"
        },
        [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowMoreVisible,
                  expression: "isShowMoreVisible"
                }
              ],
              staticClass: "commentary_button",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showMore.apply(null, arguments)
                }
              }
            },
            [_vm._v("Показать еще")]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }