<template>
  <div class="blog_item">
    <div class="blog_img">
      <img :src="article.image" :alt="article.title" v-if="article.image">
    </div>
    <p class="blog_name">{{ article.title }}?</p>
    <div class="blog_desc" v-html="article.excert"></div>
    <a :href="article.url" class="blog_more">ПОДРОБНЕЕ</a>
  </div>
</template>

<script>
    export default {
        props: {
            article: Object,
        }
    }
</script>

<style>
    .NewsCard__row {
        display: flex;
    }

    @media (max-width: 1000px) {
        .NewsCard__row {
            flex-direction: column;
        }
    }

    .NewsCard__image {
        width: 200px;
        height: 150px;
        object-fit: cover;
        margin-right: 20px;
    }
</style>