var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "NewComments" }, [
    _c("div", { ref: "splide", staticClass: "splide" }, [
      _c("div", { staticClass: "splide__track" }, [
        _c(
          "div",
          { staticClass: "splide__list" },
          _vm._l(_vm.comments, function(item) {
            return _c(
              "div",
              {
                staticClass: "splide__slide NewComments__comment",
                class: _vm.bg(item)
              },
              [
                _c("div", { staticClass: "NewComments__phone" }, [
                  _vm._v(_vm._s(item.phone))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "NewComments__name" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "NewComments__text" }, [
                  _vm._v(_vm._s(item.text))
                ]),
                _vm._v(" "),
                _c("a", {
                  staticClass: "NewComments__link",
                  attrs: { href: item.url }
                })
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }