<template>
    <section class="Chart" v-if="display">
        <div class="Chart__chart" ref="chart"></div>
    </section>
</template>

<script>
    import { Chart } from "frappe-charts/dist/frappe-charts.min.esm"

    export default {
        props: ['values'],
        mounted() {
            const data = {
                labels: this.getCurrentMonth,
                datasets: [
                    {
                        name: 'Количество',
                        values: this.dataset
                    },
                ]
            }

            const chart = this.display && new Chart(this.$refs.chart, {
                data: data,
                type: 'bar',
                height: 300,
                colors: ['#ea1919']
            });
        },
        computed: {
            dataset() {
                return Object.values(this.values);
                return this.getCurrentMonth.map(i => {
                    return this.values[i] || 0;
                });
            },
            getDates() {
                return (startDate, endDate) => {
                    const dateToString = date => {
                        let month = date.getMonth() + 1;
                        month = month < 10 ? '0' + month : month;
                        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                        return `${ date.getFullYear() }-${ month }-${ day }`;
                    }

                    let current = startDate;
                    const result = [];

                    while (+current <= +endDate) {
                        result.push(dateToString(current));
                        current = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 1);
                    }

                    return result;
                }
            },
            getCurrentMonth() {
                return Object.keys(this.values);
                const now = new Date();
                return this.getDates(
                    new Date(now.getFullYear(), now.getMonth(), 1),
                    new Date(now.getFullYear(), now.getMonth() + 1, 0)
                );
            },
            display() {
                return this.values.length || Object.keys(this.values).length;
            }
        }
    }
</script>
