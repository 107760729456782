<template>
    <div class="modal fade CommentRemoveRequest" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <form class="modal-content" @submit.prevent="submit">
                <div class="modal-header">
                    <h5 class="modal-title">Заявка на удаление отзыва</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="mb-3 d-block">
                        <span class="form-label d-block">Имя</span>
                        <input type="text" class="form-control" required v-model="name">
                    </label>
                    <label class="mb-3 d-block">
                        <span class="form-label d-block">E-mail</span>
                        <input type="email" class="form-control" required v-model="email">
                    </label>
                    <label class="mb-3 d-block">
                        <span class="form-label d-block">Причина удаления</span>
                        <textarea class="form-control" required v-model="why"></textarea>
                    </label>
                    <div class="mb-3" ref="recaptcha"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                    <button type="submit" class="btn btn-primary">Отправить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => { return {
            modal: null,
            comment: null,
            name: '',
            email: '',
            why: '',
            recaptcha: null,
            recaptchaResponce: null,
        }},
        mounted() {
            this.modal = new window.app.bootstrap.Modal(this.$el);
            this.$root.$on('remove-comment', commentId => {
                this.modal.show();
                this.comment = commentId;
            });
            this.reset();
            this.$store.dispatch('loadRecaptcha')
                .then(() => {
                    this.recaptcha = grecaptcha.render(this.$refs.recaptcha, {
                        sitekey: app.config.recaptcha,
                        callback: response => {
                            this.recaptchaResponce = response;
                        },
                        theme: 'light',
                        size: 'normal',
                    });
                })
        },
        methods: {
            submit() {
                if (!this.recaptchaResponce) return;
                this.modal.hide();
                this.$store.dispatch('removeComment', {
                    comment: this.comment,
                    name: this.name,
                    email: this.email,
                    why: this.why,
                    recaptcha: this.recaptchaResponce,
                }).then(() => this.reset());
            },
            reset() {
                this.comment = null;
                this.name = '';
                this.email = '';
                this.why = '';
                this.recaptcha && grecaptcha.reset(this.recaptcha);
                this.recaptchaResponce = null;
            }
        }
    }
</script>
