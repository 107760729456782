
<script>
    import Splide from '@splidejs/splide';

    export default {
        mounted() {
            new Splide(document.getElementById('splide-new-comments'), {
                type: 'loop',
              perPage: 4,
                perMove: 1,
                autoHeight: true,
                gap: 15,
                arrows: false,
                pagination: false,
                autoplay: true,
                interval: 3000,
                breakpoints: {
                    1000: {
                        perPage: 2,
                    },
                    500: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }
</script>

<style>
    .NewComments {
      margin-bottom: 15px;
    }

    .NewComments__comment {
      color: white;
      padding: 10px;
      position: relative;
    }

    .NewComments__name {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .NewComments__text {
      font-size: 14px;
    }

    .NewComments__phone {
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
      font-size: 20px;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
    }

    .NewComments__link {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
</style>
