var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "Toast toast show fade d-flex text-white border-0 mb-3",
      class: _vm.bgClass
    },
    [
      _c("div", {
        staticClass: "toast-body",
        domProps: { innerHTML: _vm._s(_vm.toast.text) }
      }),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn-close btn-close-white ms-auto me-2 align-self-center",
        attrs: { type: "button", "aria-label": "Close" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }