<template>
    <div class="ToastList">
        <component is="Toast" v-for="item in getToasts" :toast="item" @close="close(item)"></component>
    </div>
</template>

<script>
    export default {
        computed: {
            getToasts() {
                return this.$store.state.toasts;
            },
        },
        methods: {
            close(item) {
                this.$store.dispatch('removeToast', item);
            }
        }
    }
</script>

<style>
    .ToastList {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding: 40px;
        pointer-events: none;
    }

    @media (max-width: 1000px) {
        .ToastList {
            padding: 15px;
        }
    }
</style>